import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert, Button, Snackbar } from "@mui/material";
import React, { useCallback, useContext, useMemo, useState } from "react";
var MessageContext = React.createContext({});
export var useMessageHandler = function () {
    return useContext(MessageContext);
};
export var MessageContextProvider = function (_a) {
    var children = _a.children;
    var _b = useState(undefined), message = _b[0], setMessage = _b[1];
    var addError = useCallback(function (msg, title, action) {
        return setMessage({
            message: msg,
            isError: true,
            action: title && action ? { title: title, action: action } : undefined,
        });
    }, []);
    var addRetryableError = useCallback(function (msg, retry) {
        return setMessage({
            message: msg,
            isError: true,
            action: retry ? { title: "Retry", action: retry } : undefined,
        });
    }, []);
    var addMessage = useCallback(function (newMessage) { return setMessage(newMessage); }, []);
    var addSuccess = useCallback(function (msg, title, action) {
        return setMessage({
            message: msg,
            isError: false,
            action: title && action ? { title: title, action: action } : undefined,
        });
    }, []);
    var value = useMemo(function () { return ({ addMessage: addMessage, addSuccess: addSuccess, addError: addError, addRetryableError: addRetryableError }); }, [addMessage, addSuccess, addError, addRetryableError]);
    return (_jsxs(MessageContext.Provider, { value: value, children: [children, _jsx(Snackbar, { open: message !== undefined, onClose: function () { return setMessage(undefined); }, children: _jsx(Alert, { variant: "filled", severity: (message === null || message === void 0 ? void 0 : message.isError) ? "error" : "success", onClose: function () { return setMessage(undefined); }, action: (message === null || message === void 0 ? void 0 : message.action) && (_jsx(Button, { variant: "text", size: "small", onClick: message === null || message === void 0 ? void 0 : message.action.action, children: message === null || message === void 0 ? void 0 : message.action.title })), children: (message === null || message === void 0 ? void 0 : message.message) || "" }) })] }));
};
