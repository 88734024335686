import { createApi, fetchBaseQuery, } from "@reduxjs/toolkit/query/react";
import { getConfig } from "../common/config";
var responseHandler = function (response) {
    if (response.status === 401 ||
        response.status === 403 ||
        response.status === 409) {
        return Promise.resolve({ error: response.statusText });
    }
    return Promise.resolve(response.json());
};
var pageSize = 10;
var transformPaginatedResponse = function (apiResponse, meta, arg) {
    var _a, _b;
    return {
        pageSize: Number(arg.pageSize),
        totalElements: Number((_a = meta.response) === null || _a === void 0 ? void 0 : _a.headers.get("X-Total-Elements")),
        totalPages: Number((_b = meta.response) === null || _b === void 0 ? void 0 : _b.headers.get("X-Total-Pages")),
        data: apiResponse,
    };
};
export var tagApi = createApi({
    reducerPath: "tagApi",
    baseQuery: fetchBaseQuery({
        baseUrl: getConfig().tagApi,
        prepareHeaders: function (headers, _a) {
            var getState = _a.getState;
            var token = getState().auth.token;
            if (token) {
                headers.set("authorization", "Bearer ".concat(token));
            }
            else {
                console.error("No token found in state, can't make API call");
            }
            headers.set("Content-Type", "application/json");
            return headers;
        },
    }),
    tagTypes: ["Entity", "Entities", "Tag", "Tags"],
    endpoints: function (builder) { return ({
        getEntities: builder.query({
            query: function (ids) { return ({
                url: "/entities?size=100&entities=".concat(ids.join(",")),
                responseHandler: responseHandler,
            }); },
            providesTags: function () { return ["Entities"]; },
        }),
        getTags: builder.query({
            query: function (_a) {
                var _b = _a.page, page = _b === void 0 ? 0 : _b, _c = _a.search, search = _c === void 0 ? "" : _c, _d = _a.tags, tags = _d === void 0 ? [] : _d;
                return ({
                    url: (function () {
                        var path = "/tags?isStoreTag=false&search=".concat(search, "&size=").concat(pageSize, "&page=").concat(page);
                        tags.forEach(function (tag) { return (path = path.concat("&tags=".concat(tag))); });
                        return path;
                    })(),
                    responseHandler: responseHandler,
                });
            },
            providesTags: ["Tags"],
            transformResponse: (transformPaginatedResponse),
        }),
        getEntity: builder.query({
            query: function (id) { return ({
                url: "/entities/".concat(id),
                responseHandler: responseHandler,
            }); },
            providesTags: function () { return ["Entity"]; },
        }),
        saveTag: builder.mutation({
            query: function (tag) { return ({
                url: "/tags/".concat(tag.id),
                method: "PATCH",
                body: {
                    entities: tag.entities,
                },
                responseHandler: responseHandler,
            }); },
            invalidatesTags: function () { return [
                { type: "Tags" },
                { type: "Entity" },
                { type: "Entities" },
            ]; },
        }),
        createTag: builder.mutation({
            query: function (tag) { return ({
                url: "/tags",
                method: "POST",
                body: {
                    tag: tag.tag,
                    locked: false,
                    entities: tag.entities,
                },
                responseHandler: responseHandler,
            }); },
            invalidatesTags: function () { return [
                { type: "Tags" },
                { type: "Entity" },
                { type: "Entities" },
            ]; },
        }),
        deleteTag: builder.mutation({
            query: function (tag) { return ({
                url: "/tags/".concat(tag.id, "/entities/").concat(tag.entityId),
                method: "DELETE",
                responseHandler: responseHandler,
            }); },
            invalidatesTags: function () { return [{ type: "Entities" }, { type: "Entity" }]; },
        }),
    }); },
});
export var useGetEntitiesQuery = tagApi.useGetEntitiesQuery, useGetTagsQuery = tagApi.useGetTagsQuery, useLazyGetTagsQuery = tagApi.useLazyGetTagsQuery, useGetEntityQuery = tagApi.useGetEntityQuery, useSaveTagMutation = tagApi.useSaveTagMutation, useCreateTagMutation = tagApi.useCreateTagMutation, useDeleteTagMutation = tagApi.useDeleteTagMutation;
