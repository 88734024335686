import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createTheme, CssBaseline, responsiveFontSizes, ThemeProvider, } from "@mui/material";
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import Application from "./Application";
import { Auth0Context } from "./context/Auth0Context";
import { MessageContextProvider } from "./context/MessageContext";
import { store } from "./store/store";
import { themeOptions } from "./theme";
var AppRoot = function () {
    return (_jsxs(ThemeProvider, { theme: responsiveFontSizes(createTheme(themeOptions)), children: [_jsx(CssBaseline, {}), _jsx(Provider, { store: store, children: _jsx(BrowserRouter, { children: _jsx(Auth0Context, { children: _jsx(MessageContextProvider, { children: _jsx(Application, {}) }) }) }) })] }));
};
export default AppRoot;
