import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Box } from "@mui/material";
import { useEffect } from "react";
import { ApplicationRoutes } from "./ApplicationRoutes";
import { useAuthentication } from "./common/hooks/authentication";
import Copyright from "./components/atoms/Copyright";
import { Loading } from "./components/atoms/Loading";
import { AppMenu } from "./components/organisms/AppMenu";
import { useAppSelector } from "./store/store";
export default function Application() {
    var login = useAuthentication().login;
    var isAuthenticated = useAuth0().isAuthenticated;
    useEffect(function () {
        login();
    }, [isAuthenticated, login]);
    var Page = function () {
        var token = useAppSelector(function (store) { return store.auth.token; });
        return (_jsx(Box, { sx: {
                display: "flex",
                flexDirection: "column",
                height: "100vh",
            }, marginLeft: "3.75rem", children: token ? (_jsxs(_Fragment, { children: [_jsx(AppMenu, {}), _jsx(ApplicationRoutes, {}), _jsx(Copyright, {})] })) : (_jsx(Loading, {})) }));
    };
    var Component = withAuthenticationRequired(Page);
    return _jsx(Component, {});
}
